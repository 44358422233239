import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { CallToAction } from '~/common/models';

interface ButterBarMenuProps {
  modelName?: string;
  isVisible: boolean;
  fitContent?: boolean;
  ctaBuildYourOwn?: CallToAction;
  ctaBookTestDrive?: CallToAction;
  ctaDownloadBrochure?: CallToAction;
  ctaFindDealer?: CallToAction;
  ctaBookService?: CallToAction;
  ctaValueMyCar?: CallToAction;
  ctaShowroom?: CallToAction;
}
export const ButterBarMenu = React.memo<ButterBarMenuProps>(
  ({ modelName, isVisible, fitContent, ...cta }) => {
    const hasBottomGroup = React.useMemo(() => {
      return (
        (cta.ctaBookService && !cta.ctaBookService.ctaHidden) ||
        (cta.ctaShowroom && !cta.ctaShowroom.ctaHidden) ||
        (cta.ctaValueMyCar && !cta.ctaValueMyCar.ctaHidden)
      );
    }, [cta]);

    const count = React.useMemo(() => {
      let c = 0;
      c +=
        cta.ctaDownloadBrochure && !cta.ctaDownloadBrochure.ctaHidden ? 1 : 0;
      c += cta.ctaBuildYourOwn && !cta.ctaBuildYourOwn.ctaHidden ? 1 : 0;
      c += cta.ctaBookTestDrive && !cta.ctaBookTestDrive.ctaHidden ? 1 : 0;
      c += cta.ctaFindDealer && !cta.ctaFindDealer.ctaHidden ? 1 : 0;
      c += cta.ctaValueMyCar && !cta.ctaValueMyCar.ctaHidden ? 1 : 0;
      c += cta.ctaBookService && !cta.ctaBookService.ctaHidden ? 1 : 0;
      c += cta.ctaShowroom && !cta.ctaShowroom.ctaHidden ? 1 : 0;
      return c;
    }, [cta]);

    return (
      <div
        id="butterBarMenu"
        className={cn(
          styles.butterBarMenu,
          isVisible ? styles.butterMenuVisible : styles.butterMenuHidden
        )}
      >
        <div
          className={cn(styles.butterBarContainer, {
            [styles.butterBarContainerFull]: !fitContent,
            [styles.butterBarContainerFit]: fitContent,
          })}
        >
          <div className={styles.butterMenuGroup}>
            {cta.ctaDownloadBrochure && !cta.ctaDownloadBrochure.ctaHidden && (
              <>
                <a
                  style={!fitContent ? { width: `calc(100vw / ${count})` } : {}}
                  className={cn(styles.butterMenu, {
                    [styles.hideOnMobile]:
                      cta.ctaDownloadBrochure.ctaHideInMobile,
                  })}
                  href={cta.ctaDownloadBrochure.ctaUrl?.url}
                  target={cta.ctaDownloadBrochure.ctaUrl?.target}
                >
                  <img
                    src="/dist/assets/images/brochure.png"
                    alt={cta.ctaDownloadBrochure.ctaUrl?.name}
                  />
                  <span>{cta.ctaDownloadBrochure.ctaUrl?.name}</span>
                </a>
                <div className={styles.divider} />
              </>
            )}

            {cta.ctaBuildYourOwn && !cta.ctaBuildYourOwn.ctaHidden && (
              <>
                <a
                  style={!fitContent ? { width: `calc(100vw / ${count})` } : {}}
                  className={cn(styles.butterMenu, {
                    [styles.hideOnMobile]: cta.ctaBuildYourOwn.ctaHideInMobile,
                  })}
                  href={cta.ctaBuildYourOwn.ctaUrl?.url}
                  target={cta.ctaBuildYourOwn.ctaUrl?.target}
                >
                  <img
                    src="/dist/assets/images/build.png"
                    alt={cta.ctaBuildYourOwn.ctaUrl?.name}
                  />
                  <span>{cta.ctaBuildYourOwn.ctaUrl?.name}</span>
                </a>
                <div className={styles.divider} />
              </>
            )}

            {cta.ctaBookTestDrive && !cta.ctaBookTestDrive.ctaHidden && (
              <>
                <a
                  style={!fitContent ? { width: `calc(100vw / ${count})` } : {}}
                  className={cn(styles.butterMenu, {
                    [styles.hideOnMobile]: cta.ctaBookTestDrive.ctaHideInMobile,
                  })}
                  href={cta.ctaBookTestDrive.ctaUrl?.url}
                  target={cta.ctaBookTestDrive.ctaUrl?.target}
                >
                  <img
                    src="/dist/assets/images/test-drive.png"
                    alt={cta.ctaBookTestDrive.ctaUrl?.name}
                  />
                  <span>{cta.ctaBookTestDrive.ctaUrl?.name}</span>
                </a>
                <div className={styles.divider} />
              </>
            )}

            {cta.ctaFindDealer && !cta.ctaFindDealer.ctaHidden && (
              <>
                <a
                  style={!fitContent ? { width: `calc(100vw / ${count})` } : {}}
                  className={cn(styles.butterMenu, {
                    [styles.hideOnMobile]: cta.ctaFindDealer.ctaHideInMobile,
                  })}
                  href={cta.ctaFindDealer.ctaUrl?.url}
                  target={cta.ctaFindDealer.ctaUrl?.target}
                >
                  <img
                    src="/dist/assets/images/location.png"
                    alt={cta.ctaFindDealer.ctaUrl?.name}
                  />
                  <span>{cta.ctaFindDealer.ctaUrl?.name}</span>
                </a>
                <div className={styles.dividerLast} />
              </>
            )}
          </div>

          {hasBottomGroup && (
            <>
              <div className={styles.dividerGroup} />

              <div className={styles.butterMenuGroup}>
                {cta.ctaValueMyCar && !cta.ctaValueMyCar.ctaHidden && (
                  <>
                    <a
                      style={
                        !fitContent ? { width: `calc(100vw / ${count})` } : {}
                      }
                      className={cn(styles.butterMenu, {
                        [styles.hideOnMobile]:
                          cta.ctaValueMyCar.ctaHideInMobile,
                      })}
                      href={cta.ctaValueMyCar.ctaUrl?.url}
                      target={cta.ctaValueMyCar.ctaUrl?.target}
                    >
                      <img
                        src="/dist/assets/images/value-my-car.png"
                        alt={cta.ctaValueMyCar.ctaUrl?.name}
                      />
                      <span>{cta.ctaValueMyCar.ctaUrl?.name}</span>
                    </a>
                    <div className={styles.divider} />
                  </>
                )}

                {cta.ctaBookService && !cta.ctaBookService.ctaHidden && (
                  <>
                    <a
                      style={
                        !fitContent ? { width: `calc(100vw / ${count})` } : {}
                      }
                      className={cn(styles.butterMenu, {
                        [styles.hideOnMobile]:
                          cta.ctaBookService.ctaHideInMobile,
                      })}
                      href={cta.ctaBookService.ctaUrl?.url}
                      target={cta.ctaBookService.ctaUrl?.target}
                    >
                      <img
                        src="/dist/assets/images/bookservice.png"
                        alt={cta.ctaBookService.ctaUrl?.name}
                      />
                      <span>{cta.ctaBookService.ctaUrl?.name}</span>
                    </a>
                    <div className={styles.divider} />
                  </>
                )}

                {cta.ctaShowroom && !cta.ctaShowroom.ctaHidden && (
                  <a
                    style={
                      !fitContent ? { width: `calc(100vw / ${count})` } : {}
                    }
                    className={cn(styles.butterMenu, {
                      [styles.hideOnMobile]: cta.ctaShowroom.ctaHideInMobile,
                    })}
                    href={cta.ctaShowroom.ctaUrl?.url}
                    target={cta.ctaShowroom.ctaUrl?.target}
                  >
                    <img
                      src="/dist/assets/images/pricing.png"
                      alt={cta.ctaShowroom.ctaUrl?.name}
                    />
                    <span>{cta.ctaShowroom.ctaUrl?.name}</span>
                  </a>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
);
