import React from 'react';
import { ButterBarMenu } from '~/common/components/butter-bar-menu';
import { CallToAction } from '~/common/models';

interface Props {
  modelName: string;
  hasHybrid: boolean;
  element?: HTMLDivElement | string;
  ctaBuildYourOwn?: CallToAction;
  ctaBookTestDrive?: CallToAction;
  ctaDownloadBrochure?: CallToAction;
  ctaFindDealer?: CallToAction;
  ctaBookService?: CallToAction;
  ctaValueMyCar?: CallToAction;
  ctaShowroom?: CallToAction;
}

export default ({ modelName, hasHybrid, element, ...butterMenu }: Props) => {
  const [butterBarVisible, setButterBarVisible] =
    React.useState<boolean>(false);

  const targetElement = React.useMemo(() => {
    if (!!element && typeof document !== 'undefined') {
      if (typeof element === 'object') return element;
      if (typeof element === 'string') return document.getElementById(element);
    }
  }, [element]);

  const handleScroll = React.useCallback(() => {
    if (targetElement) {
      const targetPosition = targetElement.getBoundingClientRect().top - 100;
      const scrollPosition = window.scrollY;
      setButterBarVisible(scrollPosition >= targetPosition);
    } else {
      setButterBarVisible(true);
    }
  }, [targetElement]);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <ButterBarMenu
      modelName={modelName}
      isVisible={butterBarVisible}
      {...butterMenu}
    />
  );
};
